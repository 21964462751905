import * as React from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";

import { Helmet } from "react-helmet";

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

// markup
const DetailPage = ({ data: { prismicAlbum } }) => {
  const { data: albumData } = prismicAlbum;
  const { title } = albumData;
  return (
    <Layout>
      <Helmet>
        <title>{title?.text}</title>
      </Helmet>
      <main style={pageStyles}>
        {/* <h1 style={headingStyles}>
        {title?.text}
        <br />
        <span role="img" aria-label="Party popper emojis">
          🎉🎉🎉
        </span>
      </h1> */}
      </main>
    </Layout>
  );
};

export default DetailPage;

export const query = graphql`
  query PageTemplate($id: String!) {
    prismicAlbum(id: { eq: $id }) {
      _previewable
      data {
        title {
          text
        }
      }
    }
  }
`;
